<template>
  <main class="main-content signup">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain" style="margin-top: 150px;" v-if="!reset">
                <div class="pb-0 card-header text-start">
                  <h1 class="font-weight-bolder">{{ $t("views.signIn.login") }}</h1>
                </div>
                <div class="card-body">
                  <form @submit.prevent="">
                    <div class="mb-3">
                      <div class="input-group">
                        <span class="input-group-text"><i class="fa-duotone fa-envelope"></i></span>
                        <input type="text" name="user" class="form-control form-control-lg"
                               @keypress.enter="login(credentials)" v-model="credentials.username"
                               :placeholder="$t('globals.email')"/>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="input-group">
                        <template v-if="passwordShow">
                          <span class="input-group-text"><i class="fa-duotone fa-lock"></i></span>
                          <input type="text" class="form-control form-control-lg" @keypress.enter="login(credentials)"
                                 v-model="credentials.password" :placeholder="$t('views.signIn.password')"
                                 name="password"/>
                          <span class="passwordEye" @click="showPassword"><i class="fa-duotone fa-eye-slash"></i></span>
                        </template>
                        <template v-else>
                          <span class="input-group-text"><i class="fa-duotone fa-lock"></i></span>
                          <input type="password" autocomplete="true" class="form-control form-control-lg"
                                 @keypress.enter="login(credentials)" v-model="credentials.password"
                                 :placeholder="$t('views.signIn.password')"
                                 name="password"/>
                          <span class="passwordEye" @click="showPassword"><i class="fa-duotone fa-eye"></i></span>
                        </template>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="input-group">
                        <span class="input-group-text"><i class="fa-duotone fa-shield-check"></i></span>
                        <input type="text" class="form-control form-control-lg" @keypress.enter="login(credentials)"
                               v-model="credentials.mfa" :placeholder="$t('views.signIn.twoFactorAuthentication')"/>
                      </div>
                    </div>
                    <!--                    <argon-switch id="rememberMe">Remember me</argon-switch>-->
                    <div class="text-center">
                      <button
                        class="mt-4 btn btn-primary w-100"
                        @click="login(credentials)"
                      >{{ $t("views.signIn.login") }}
                      </button>
                    </div>
                    <div class="text-center text-sm">
                      <p style="font-size: 12px; padding-top: 12px">{{ $t("views.signIn.forgotPassword") }}? <a href=""
                                                                                                                @click.prevent="resetPassword()">{{
                          $t("views.signIn.resetPassword")
                        }}</a>.
                      </p>
                    </div>
                  </form>
                </div>
              </div>
              <div class="card card-plain" style="margin-top: 150px;" v-else>
                <div class="pb-0 card-header text-start">
                  <h1 class="font-weight-bolder">{{ $t("views.signIn.passwordReset") }}</h1>
                </div>
                <div class="card-body">
                  <div class="mb-3">
                    <div class="input-group">
                      <template v-if="reset1Show">
                        <span class="input-group-text"><i class="fa-duotone fa-lock"></i></span>
                        <input type="text" class="form-control form-control-lg" v-model="credentials.password"
                               :placeholder="$t('views.signIn.newPassword')"/>
                        <span class="passwordEye" @click="showReset1"><i class="fa-duotone fa-eye-slash"></i></span>
                      </template>
                      <template v-else>
                        <span class="input-group-text"><i class="fa-duotone fa-lock"></i></span>
                        <input type="password" class="form-control form-control-lg" v-model="credentials.password"
                               :placeholder="$t('views.signIn.newPassword')"/>
                        <span class="passwordEye" @click="showReset1"><i class="fa-duotone fa-eye"></i></span>
                      </template>
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="input-group">
                      <template v-if="reset2Show">
                        <span class="input-group-text"><i class="fa-duotone fa-lock"></i></span>
                        <input type="text" class="form-control form-control-lg" v-model="credentials.password2"
                               :placeholder="$t('views.signIn.repeatPassword')"/>
                        <span class="passwordEye" @click="showReset2"><i class="fa-duotone fa-eye-slash"></i></span>
                      </template>
                      <template v-else>
                        <span class="input-group-text"><i class="fa-duotone fa-lock"></i></span>
                        <input type="password" class="form-control form-control-lg" v-model="credentials.password2"
                               :placeholder="$t('views.signIn.repeatPassword')"/>
                        <span class="passwordEye" @click="showReset2"><i class="fa-duotone fa-eye"></i></span>
                      </template>
                    </div>
                  </div>
                  <div class="text-center">
                    <button
                      class="mt-4 btn btn-primary w-100"
                      @click="changePassword(credentials)"
                    >{{ $t("views.signIn.changePassword") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div id="slideBox" :key="currentSlide.title" :style="'background-image: url('+currentSlide.image+');'"
                   class="position-relative bg-gradient-primary h-100 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
              >
                <div class="wordBox">
                  <h5
                    class="mt-5  font-weight-bolder position-relative"
                  >{{ $t("views.signIn.sketchManager") }}</h5>
                  <p
                    class=" position-relative introline"
                  >{{ currentSlide.subtitle }}</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
const body = document.getElementsByTagName("body")[0];
import {mapActions} from "vuex";
import axios from "axios";

export default {
  name: "signin",
  data() {
    return {
      reset: false,
      credentials: {
        email: "",
        password: "",
        password2: ""
      },
      currentSlide: {
        title: this.$t("views.signIn.sketchManager"),
        subtitle: this.$t("views.signIn.subtitle"),
        image: "/img/sketch.connect_intro.gif"
      },
      slides: [],
      passwordShow: false,
      reset1Show: false,
      reset2Show: false,
    }
  },
  methods: {
    ...mapActions([
      'login',
      'logout'
    ]),
    changePassword() {
      if (this.credentials.password !== this.credentials.password2) {
        this.$store.dispatch("toastError", this.$t("toast.error.passwordNotMatch"));
      } else {
        axios.put(this.$store.state.apiUrl + "login", {
          changePassword: this.$route.params.resetToken,
          password: this.credentials.password,
          password2: this.credentials.password2
        }).then(() => {
          this.$store.dispatch("toastSuccess", this.$t("toast.success.youCanLogin"));
          localStorage.removeItem("resetPassword");
          this.reset = false;
          this.$router.push({name: "signin"});
        }).catch((r) => {
          this.$store.dispatch("handleApiResponse", r.response);
        });
      }
    },
    resetPassword() {
      if (!this.credentials.username) {
        this.$store.dispatch("toastError", this.$t("toast.error.insertEmail"));
      } else {
        this.$confirm(this.$t("views.signIn.confirmPasswordResetText"), this.$t("views.signIn.confirmPasswordReset"), 'question', {
          confirmButtonText: this.$t("globals.yes"),
          cancelButtonText: this.$t("globals.no"),
          type: 'warning'
        }).then(() => {
          // get baseurl
          let href = window.location.href;
          // generate random string
          let randomString = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
          let returnUrl = href + "/" + randomString;
          localStorage.setItem("resetPassword", randomString);
          axios.put(this.$store.state.apiUrl + "login", {
            resetPassword: this.credentials.username,
            returnUrl: returnUrl
          }).then(() => {
            this.$store.dispatch("toastSuccess", this.$t("toast.success.checkEmail"));
          }).catch((r) => {
            this.$store.dispatch("handleApiResponse", r.response);
          });
        })
      }
    },
    changeSlide() {
      // change slide every 7 seconds
      let i = 0;
      setInterval(() => {
        if (i < this.slides.length - 1) {
          i++;
        } else {
          i = 0;
        }
        this.currentSlide = this.slides[i];
      }, 7000);
    },
    showPassword() {
      this.passwordShow = !this.passwordShow;
    },
    showReset1() {
      this.reset1Show = !this.reset1Show;
    },
    showReset2() {
      this.reset2Show = !this.reset2Show;
    }
  },
  created() {
    if (this.$route.params && this.$route.params.resetId) {
      if (localStorage.getItem("resetPassword") === this.$route.params.resetId) {
        this.reset = true;
        this.$store.dispatch("toastSuccess", this.$t("toast.success.insertPassword"));
      } else {
        // check if route name is SigninReset
        if (this.$route.name === "SigninReset") {
          this.$store.dispatch("toastError", this.$t("toast.error.linkOutdated"));
          this.$router.push("/signin");
        }
      }
    }
  },
};
</script>
<style>
#wrapper.Signin .appContainer {
  padding: 0;
}

#slideBox {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (min-width: 1300px) {
  #slideBox {
    background-size: 800px 638px;
  }
}
</style>
