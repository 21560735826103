<template>
  <div class="row mb-3">
    <div class="col-12">
      <div class="card mb-3">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 col-12">
              <div class="form-group">
                <label for="search">Suche</label>
               <customersearch :small="true" :onlyid="true"  v-model="filter_customer" @changed-customer="filter" />

              </div>
            </div>
            <div class="col-lg-3 col-12">
              <div class="form-group">
                <label for="employee">Mitarbeiter</label>
                <select id="employee" class="form-select small" v-model="filter_owner_id" v-if="getEmployees"
                        @change="filter">
                  <option value="">Alle</option>
                  <option v-for="employee in getEmployees" :value="employee.id" v-bind:key="employee.id">
                    {{ employee.e_name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-2 col-12">
              <div class="form-group">
                <label for="day">Tag</label>
                <input type="number" v-model="filter_day" @change="filter" class="form-control small" id="day" min="1" max="31">
              </div>
            </div>
            <div class="col-lg-2 col-12">
              <div class="form-group">
                <label for="month">Monat</label>
                <select id="month" class="form-select small" v-model="filter_month" @change="filter">
                  <option value="">Alle</option>
                  <option value="01">Januar</option>
                  <option value="02">Februar</option>
                  <option value="03">März</option>
                  <option value="04">April</option>
                  <option value="05">Mai</option>
                  <option value="06">Juni</option>
                  <option value="07">Juli</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">Oktober</option>
                  <option value="11">November</option>
                  <option value="12">Dezember</option>
                </select>
              </div>
            </div>

            <div class="col-lg-2 col-12">
              <div class="form-group">
                <label for="year">Jahr</label>
                <select id="year" class="form-select small" v-model="filter_year" @change="filter">
                  <option v-for="year in years" :value="year" v-bind:key="year">{{ year }}</option>
                </select>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <table class="table table-striped table-hover">
                  <thead>
                  <tr>
                    <th>Task</th>
                    <th class="center">Mitarbeiter</th>
                    <th>Stunden</th>
                    <th>Datum</th>
                    <th>Notiz</th>
                    <th>Aktivität</th>
                    <th>Projekt</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="time in times" :key="time.id">
                    <td v-if="time._issue"><template v-if="time._issue.title"><button @click="$router.push({name: 'TaskDetails', params: {id: time.issue_id}});" type="button" class="btn btn-outline-primary btn-sm w-100" :tooltip="time._issue.title" position="right">{{ time.issue_id }}</button></template></td>
                    <td class="center"><img style="max-width: 40px"
                             :src="$filters.employeeIcon(getEmployees, time.employee_id, 'id')"
                             class="sketchAvatar"></td>
                    <td>{{ time.hours }}</td>
                    <td>{{ $filters.formatDate(time.date) }}</td>
                    <td>{{ time.comment }}</td>
                    <td><template v-if="time._issue && time._issue.infos && time._issue.infos.tracker && time._issue.infos.tracker.title">{{time._issue.infos.tracker.title}}</template></td>
                    <td><template v-if="time._issue && time._issue.infos && time._issue.infos.project && time._issue.infos.project.title">{{time._issue.infos.project.title}}</template></td>
                  </tr>
                  </tbody>

                  <tfoot>
                  <tr>

                     <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td><strong>{{timeHoursFull}}</strong></td>
                      <td colspan="4">&nbsp;</td>

                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import Datepicker from '@vuepic/vue-datepicker';
import customersearch from "@/filter/components/customersearch";
export default {
  name: 'TicketTime',
  components: {
    Datepicker, customersearch
  },
  computed: {
    ...mapGetters(['getUser', 'getEmployees']),
    timeHoursFull() {
      let hours = 0;
      for (let i = 0; i < this.times.length; i++) {
        if(this.times[i].hours){
        hours += parseFloat(this.times[i].hours);
        }
        }
      hours = hours.toFixed(2);
      return hours;
    },
    years() {
      let years = [];
      for (let i = 2020; i <= new Date().getFullYear(); i++) {
        years.push(i);
      }
      return years;
    }
  },
  data() {
    return {
      selectedEmployee: null,
      selectedMonth: null,
      selectedYear: null,
      filter_query: '',
      filter_customer: '',
      filter_owner_id: '',
      filter_month: '',
      filter_year: '',
      filter_day: new Date().getDate(),
      times: []
    }
  },
  methods: {
    filter() {
      this.filter_query = '';
      if (this.filter_owner_id) {
        this.filter_query += '?filter_owner=' + this.filter_owner_id;
      } else {
        this.filter_query += '?filter_owner=';
      }
      if (this.filter_month) {
        this.filter_query += '&filter_month=' + this.filter_month;
      }
      if (this.filter_year) {
        this.filter_query += '&filter_year=' + this.filter_year;
      }
      if (this.filter_customer) {
        this.filter_query += '&filter_customer_id=' + this.filter_customer;
      }
      if (this.filter_day) {
        this.filter_query += '&filter_day=' + this.filter_day;
      }
      let VueInner = this;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "issueTimes/search" + this.filter_query, {headers}).then((r) => {

        VueInner.times = r.data.result.data;

      }).catch((r) => {
        VueInner.$store.dispatch("handleApiResponse", r.response);
      });


    },
    setDefaults() {
      if (this.getUser && this.getUser._isEmployee) {
        this.filter_owner_id = this.getUser._isEmployee;
      }
      // get Current month like this: 01, 02, 03, ...
      let date = new Date();
      this.filter_month = ("0" + (date.getMonth() + 1)).slice(-2);
      this.filter_year = date.getFullYear();
    },
  },
  mounted() {
    this.setDefaults();
    this.filter();
  },
}
</script>
